<template>
  <div>
    <v-row v-if="program.current_moderations && program.current_moderations.length">
      <v-col>
        <base-banner
          text="Ваша компания находится на модерации. Модерацию будут проходить самые свежие изменения."
          type="warn"
          closeable
        />
      </v-col>
    </v-row>

    <BaseMasterFieldBlock
      title="Название"
      :horisontal="true"
    >
      <span
        slot="description"
      >Юридическое название компании без кавычек и формы собственности. Есть фирменное название? Отлично! Вводите его.</span>
      <template v-slot:input>
        <base-text-field
          v-model="program.name"
          :validate-on-blur="true"
          placeholder="Название компании"
          outlined
          counter
          minlength="1"
          maxlength="20"
          :rules="[
            v => !!v || 'Название компании обязательно',
            v => v.length <= 20 || 'Название компании не должно быть более 20 символов'
          ]"
        />
      </template>
    </BaseMasterFieldBlock>

    <BaseMasterFieldBlock
      title="Слоган"
    >
      <span slot="description">Напишите дополнительный текст, который позволит клиенту лучше понять чем ваша компания занимается, к чему стремится.</span>
      <template v-slot:input>
        <base-text-field
          v-model="program.slogan"
          :validate-on-blur="true"
          placeholder="Слоган компании"
          outlined
          counter
          maxlength="100"
          :rules="[
            v => !v || v.length <= 100 || 'Название компании не должно быть более 20 символов'
          ]"
        />
      </template>
    </BaseMasterFieldBlock>

    <v-row>
      <v-col>
        <BaseMasterFieldBlock
          title="Карта клиента"
        >
          <div
            slot="description"
          >
            <div>Вот так будет выглядеть ваша карта в приложении.</div>
            <div class="info-card-text">
              <span>1. Измените цвет карты, кликнув на иконку</span>
              <iconify-icon
                class="info-card-text-icon"
                icon="ion-color-fill-outline"
                height="21"
              />
              <span>в шаблоне справа<span class="info-card-text-req">*</span></span>
            </div>
            <div class="info-card-text">
              <span>2. Загрузите логотип, кликнув на иконку</span>
              <iconify-icon
                class="info-card-text-icon"
                icon="feather-download"
                height="21"
              />
              <span class="info-card-text-req">*</span>
            </div>
          </div>
        </BaseMasterFieldBlock>
      </v-col>
      <v-col style="padding-top: 75px;">
        <company-card
          :program="program"
        />
      </v-col>
    </v-row>

    <BaseMasterFieldBlock
      title="Правила"
    >
      <span slot="description">Напишите правила программы программы лояльности.</span>
      <template v-slot:input>
        <v-textarea
          v-model="program.conditions"
          :placeholder="conditionsPlaceholder"
          rows="4"
          outlined
          no-resize
          readonly
          maxlength="20000"
          @click="()=>{
            editConditions = program.conditions
            editConditionsDialog = true
          }"
        />
      </template>
    </BaseMasterFieldBlock>

    <BaseMasterFieldBlock
      title="Выпуск карты"
    >
      <span
        slot="description"
      >Хотите ли вы дать вашим клиентам возможность выпускать карту самостоятельно в приложении? Если выключить параметр, то карту сможет выпустить только ваш сотрудник.</span>
      <template v-slot:input>
        <v-switch
          v-model="program.allow_issue"
          inset
          label="Разрешить самостоятельный выпуск карты"
        />
      </template>
    </BaseMasterFieldBlock>
    <BaseMasterFieldBlock
      title="Прикрепление документов"
    >
      <span
        slot="description"
      >Хотите ли вы дать вашим клиентам возможность прикреплять документы к карте лояльности?</span>
      <template v-slot:input>
        <v-switch
          v-model="program.doc_attach_enabled"
          inset
          label="Разрешить прикрепление документов"
        />
      </template>
    </BaseMasterFieldBlock>
    <message-box
      v-model="editConditionsDialog"
      scrollable
      title="Правила программы лояльности"
      max-width="80%"
      type-mode="prompt"
      show-cancel-button
      @confirm="program.conditions = editConditions"
    >
      <v-textarea
        v-model="editConditions"
        :placeholder="conditionsPlaceholder"
        rows="20"
        :auto-grow="false"
        outlined
        clearable
        maxlength="20000"
        @focus="() => { if(!editConditions) editConditions = conditionsPlaceholder }"
      />
    </message-box>
    <BaseMasterFieldBlock
      title="Валюта продаж"
    >
      <span
        slot="description"
      >Выберите валюту в которой производится оплата продаж</span>
      <template v-slot:input>
        <v-autocomplete
          v-model="program.currency_id"
          class="model-select__shop"
          outlined
          clearable
          deletable-chips
          auto-select-first
          hide-selected
          :items="currency"
          item-value="id"
          item-text="name_sumbol"
          item-disabled="deleted_at"
          :rules="[
                  v => !!v || 'Выберите валюту'
          ]"
        />
      </template>
    </BaseMasterFieldBlock>
  </div>
</template>

<script>
  import CompanyCard from '@/components/dialogs/CompanyCard'
  import { conditions } from '@/assets/json/program-conditions.js'
  import { mapGetters, mapActions } from 'vuex'
  import Vue from 'vue'

  export default {
    components: {
      CompanyCard,
      MessageBox: () => import('@/components/message-box/MessageBox.vue'),
    },
    props: {
      program: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data () {
      return {
        editConditionsDialog: false,
        editConditions: null,
      }
    },
    computed: {
      ...mapGetters({
        requisites: 'settings/organization/requisites',
        currency: 'company/program/currency'
      }),

      conditionsPlaceholder () {
        return conditions
          .replace('{program.name}', this.program.name)
          .replace('{merchant.org.name}', this.requisites.name || '{merchant.org.name}')
          .replace('{merchant.org.ogrn}', this.requisites.ogrn || '{merchant.org.ogrn}')
          .replace('{merchant.org.inn}', this.requisites.inn || '{merchant.org.inn}')
          .replace('{merchant.org.address}', this.requisites.address || '{merchant.org.address}')
          .trim()
      },
    },
    async created () {
      Vue.set(this.program, 'conditions', this.program.conditions || '' )
      await this.getDetails()
    },
    methods: {
      ...mapActions({
        getDetails: 'settings/organization/details',
        getCurrency: 'company/program/getCurrency',
      }),
      // onConditionsFocus () {
      //   console.log('onConditionsFocus')
      //   if (!this.program.conditions) {
      //     this.program.conditions = this.conditionsPlaceholder
      //   }
      // },
    },
    async mounted () {
      await this.getCurrency();
    }

  }
</script>

<style lang="scss" scoped>
@import 'form-component.scss';
</style>
